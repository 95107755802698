<template>
  <div class="attachment-list-item-container">
    <attachment>
      <template>
        {{ fileName }}
      </template>
      <template #right>
        <b-button class="button is-small px-1" @click="onDeleteClick">
          <div class="is-flex is-justify-content-center is-align-items-center" style="width: 24px; height: 24px">
            <trash-bin-icon class="svg-icon has-fill-primary"></trash-bin-icon>
          </div>
        </b-button>
      </template>
    </attachment>
  </div>
</template>

<script>
import Attachment from "@/web/components/shared/Attachment";
import TrashBinIcon from "@/assets/icons/trash-bin.svg";

export default {
  name: "AttachmentFileListView",
  components: {
    Attachment,
    TrashBinIcon,
  },
  props: {
    attachment: {
      type: Object,
      required: true,
    },
  },

  computed: {
    fileName() {
      return this.attachment.file_name;
    },

    fileSize() {
      return this.attachment.size;
    },
  },

  methods: {
    onDeleteClick() {
      this.$emit("delete", this.attachment.id);
    },
  },
};
</script>

<style scoped></style>
