<template>
  <div class="accesses-exhibitor-list-item-container" @click="navigateToModeratorExhibitor">
    <div class="box is-borderless is-clipped has-logo px-0 py-0 my-0 is-flex-shrink-1">
      <figure class="image is-centered is-60x60">
        <img class="has-image-centered" :src="logoUrl" />
      </figure>
    </div>
    <div class="is-flex-grow-1">
      <div class="accesses-exhibitor-list-item-title">{{ title }}</div>
      <div class="accesses-exhibitor-list-item-subtitle">{{ subtitle }}</div>
    </div>
    <b-button type="is-outlined" class="pl-1 pr-1" slot="trigger">
      <div class="accesses-exhibitor-list-item-edit-icon">
        <edit-icon class="svg-icon has-fill-primary"></edit-icon>
      </div>
    </b-button>
  </div>
</template>

<script>
import EditIcon from "@/assets/icons/edit.svg";
import Constants from "@/web/constants";

export default {
  name: "ExhibitorAccessListItem",
  components: { EditIcon },

  props: {
    exhibitor: {
      type: Object,
      required: true,
    },
  },

  computed: {
    logoUrl() {
      const picture = this.exhibitor.picture;
      if (picture) {
        return picture && picture.file_thumbnail_200_url;
      } else {
        return require("@/assets/photo_placeholder.png");
      }
    },

    title() {
      return this.exhibitor.name;
    },

    subtitle() {
      return this.exhibitor.scope_description;
    },
  },

  methods: {
    navigateToModeratorExhibitor() {
      this.$router.push({
        name: Constants.ROUTE_MODERATOR_EXHIBITOR,
        params: {
          exhibitorId: this.exhibitor.id,
        },
      });
    }
  }
};
</script>

<style scoped></style>
